.post-content {
  p, blockquote, pre,
  ul, ol, dl, figure,
  %vertical-rhythm {
    font: $content-font-weight #{$content-font-size}/#{$content-line-height} $content-font-family;
  }
}

.site-title::before {
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin-left: -1.05em;
  margin-right: 0.25em;
  background-color: $brand-color;
}

.post-meta {
  color: inherit;
}

blockquote {
  color: inherit;
  background-color: $code-background-color;
  margin-left: 1.5em;
  margin-right: 1em;
}

code {
  background-color: inherit;
  border: inherit;
  border-radius: inherit;
  padding: inherit;
}

.twitter-tweet.twitter-tweet-rendered {
  margin: 0 auto;
}